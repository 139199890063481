<script setup>
//import routingMixin from '~/mixins/routing';
const props = defineProps(['children']);
const { locale } = useI18n();
const localePath = useLocalePath();

const createLink = (child) => {
  let linkObject;
  if (child?.route_id) {
    linkObject = useNoTemplating(child.route_id);
  } else {
    linkObject = {
      name: 'about-page-slug',
      params: { slug: child.slug[locale.value] },
    };
  }
  return linkObject;
};
</script>

<template>
  <div class="mt-6 card-list">
    <Card
      v-for="(child, index) in children"
      :key="child.id"
      :link="localePath(createLink(child))"
      :external="child.external_link"
      :heading="child.name"
      :image-src="child.image"
      mobile-image-ratio="2:1"
      vcenter
    >
      <p v-if="child.perex" class="text text--2" v-html="child.perex" />
    </Card>
  </div>
</template>

<style scoped>
.card-list {
  display: block;
  .card {
    padding-top: 20px;
    border-bottom: 1px solid silver;
    &:first-child {
      border-top: 1px solid silver;
    }
  }
}
</style>
